<div class="card">
    <div class="card-body" id="history">
        <div class="table-responsive table-wrapper" *ngIf="(audit ?? []).length > 0" >
            <h5>{{heading}}</h5>
            <table class="table table-bordered table-hover table-panel">
                <thead>
                    <tr>
                        <th width="10%">User</th>
                        <th width="10%">User Id</th>
                        <th width="15%">Date</th>
                        <th width="20%">Action</th>
                        <th width="45%">Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of audit" class="settingRow">
                        <td>{{ data.auditUser }}</td>
                        <td>{{ data.auditUserId }}</td>
                        <td>{{ data.createdDate | date: 'short'}}</td>
                        <td>{{ data.auditAction }}</td>
                        <td>{{ data.auditData }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>