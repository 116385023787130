<app-spinner-with-overlay [showSpinner]="orderLayoutService.loadingOrder || orderLayoutService.loadingOrderItem">
	<div class="card">
		<div class="card-header">
			<ul class="nav nav-tabs card-header-tabs" role="tablist">
				<li class="nav-item">
					<button class="nav-link active" aria-current="true" data-bs-toggle="tab"
						data-bs-target="#detailView">Detail</button>
				</li>
				<li class="nav-item" *ngIf="(orderLayoutService.selectedOrderItemAsCartProduct?.drops && orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops!.length > 0) || (countryCode === 'Canada' && isLegacyDigital) || (orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId === uSelect.qrCode && !!qrCodeMetaInfo)">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#deliveryView">Delivery</button>
				</li>
				<li class="nav-item" *ngIf="!showDesignForDigital && frontPreview">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#previewFrontView">Art Preview
						(Front)</button>
				</li>
				<li class="nav-item" *ngIf="!showDesignForDigital && backPreview">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#previewBackView">Art Preview
						(Back)</button>
				</li>
				<li class="nav-item" *ngIf="orderLayoutService.isSystemAdmin">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#previewRaw">Raw Order Item</button>
				</li>
				<li class="nav-item" *ngIf="orderLayoutService.selectedOrderItemAsCartProduct && orderLayoutService.isSystemAdmin">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#previewProduct">Cart Product</button>
				</li>
				<li class="nav-item" *ngIf="orderLayoutService.selectedOrderItemAsCartProduct">
					<button class="nav-link" data-bs-toggle="tab" data-bs-target="#history">Order Item History</button>
				</li>
			</ul>
		</div>
		<div class="tab-content">
			<div class="tab-pane fade show active card-body" id="detailView">
				<div class="row">
					<div class="col-md-6">
						<div class="card mb-3" [ngClass]="{ 'bg-danger': orderLayoutService.selectedOrderItem?.orderItem?.deleted }">
							<div class="card-body">
								<div class="alert alert-danger" *ngIf="isUnmappedQuoteItem">
									The product id on this item is 0. It is unfulfillable in its current state.
								</div>
								<div class="row">
									<div class="col-auto">
										<h5 class="card-title">
											<app-placeholder [dependsOn]="orderLayoutService.selectedOrderItem">
												{{ orderLayoutService.selectedOrderItem?.quantity | number }}
											</app-placeholder>
											<i class="far fa fa-times"></i>
											{{ orderItemName }}
											<span *ngIf="orderLayoutService.selectedOrderItem?.orderItem?.deleted">
												- Deleted
											</span>
										</h5>
									</div>
									<div class="col ms-auto text-end">
										<app-placeholder [dependsOn]="orderLayoutService.selectedOrderItem">
											#{{orderLayoutService.selectedOrderItem?.orderItemId}}
										</app-placeholder>
									</div>
								</div>
								<ng-container *ngIf="!orderLayoutService.loadingOrderItem && !orderLayoutService.loadingOrder && orderLayoutService.selectedOrderItem?.orderItem?.lines; else waitingForLines">
									<ng-container *ngIf="!seeAllOrderItemLines">
										<div class="row">
											<div class="col">
												<table class="table table-borderless table-sm table-hover">
													<tbody>
														<tr *ngFor="let line of orderLayoutService.orderItemLinesDisplay">
															<td>
																<div class="row">
																	<div class="col">
																		<strong>{{ line.description }}</strong>
																	</div>
																	<div class="col">
																		{{ line.quantity | number }}&#64;{{ line.unitPrice | currency:'USD':'symbol':'1.2-4' }}
																	</div>
																	<div class="col">
																		{{ line.amount | currency }}
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="col-auto">
												<button class="btn btn-info" (click)="seeAllOrderItemLines = !seeAllOrderItemLines">Expand</button>
											</div>
										</div>
									</ng-container>
									<ng-container *ngIf="seeAllOrderItemLines">
										<div class="row">
											<div class="col">
												<table class="table table-borderless table-sm table-hover">
													<tbody>
														<tr *ngFor="let line of orderLayoutService.selectedOrderItem?.orderItem?.lines">
															<td>
																<div class="row">
																	<div class="col">
																		<strong>{{ line.description }}</strong>
																	</div>
																	<div class="col">
																		{{ line.createdDate | date: 'yyyy-MM-dd' }}
																	</div>
																	<div class="col">
																		{{ line.quantity | number }}&#64;{{ line.unitPrice | currency:'USD':'symbol':'1.2-4' }}
																	</div>
																	<div class="col">
																		{{ line.amount | currency }}
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="col-auto">
												<div class="col-auto">
													<button class="btn btn-info" (click)="seeAllOrderItemLines = !seeAllOrderItemLines">Minimize</button>
												</div>
											</div>
										</div>
									</ng-container>
									<ul class="list-unstyled ms-2">
										<li
											*ngFor="let attribute of orderLayoutService.selectedOrderItem?.orderItem?.attributes">
											<b>{{attribute.attributeName}}: </b> {{attribute.attributeValue}}
										</li>

										<ul class="list-unstyled" *ngIf="orderLayoutService.selectedOrderItemAsCartProduct?.meta">
											<li *ngFor="let key of getMetaKeys()">
												<b>{{key}}: </b> {{orderLayoutService.selectedOrderItemAsCartProduct?.meta![key]}}
											</li>
										</ul>
										<li *ngIf="orderLayoutService.selectedOrderItem?.jobName">
											<b>Job Name: </b>{{orderLayoutService.selectedOrderItem?.jobName}}
										</li>
										<li *ngIf="orderLayoutService.selectedOrderItem?.jobComments">
											<b>Comments: </b>{{orderLayoutService.selectedOrderItem?.jobComments}}
										</li>
									</ul>
								</ng-container>
								<ng-template #waitingForLines>
									Loading order item lines...
								</ng-template>
							</div>
						</div>
						<div class="card mb-3" *ngIf="!orderLayoutService.loadingOrder && ((!!orderLayoutService.selectedOrderItemAsCartProduct?.shippingAddressId && orderLayoutService.selectedOrderItemAsCartProduct?.shippingAddressId! > 0) || extraCopiesQty)">
							<div class="card-body border border-danger">
								<div *ngIf="extraCopiesQty > 0" class="card-title fw-bold">
									<h5>{{extraCopiesQty}} Extra Copies</h5>
								</div>
								<div class="row">
									<div class="col-6">
										<app-customer-addresses [addressType]="'Shipping'"
											[customerId]="orderLayoutService.selectedCustomer?.customerID!"
											[cartAddressId]="orderLayoutService.selectedOrderItemAsCartProduct?.shippingAddressId"
											[allowCopyEdit]="true"
											(addressSelected)="setShippingAddress($event)">
										</app-customer-addresses>
									</div>
									<ng-container *ngIf="shippingAddressDeleted || shippingAddressMissingPhoneNumber">
										<div class="col-6 d-flex flex-column justify-content-evenly">
											<p *ngIf="shippingAddressDeleted" class="text-danger">
												Selected shipping address was deleted <span *ngIf="!!shippingAddressDeletedDate">on <br />{{ shippingAddressDeletedDate | date }}</span>
											</p>
											<p *ngIf="shippingAddressMissingPhoneNumber" class="text-danger">
												Selected shipping address is missing a phone number, which is required <br />
											</p>
											<p class="text-danger">
												Click "Copy and Edit" to fix this address
											</p>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
						<div class="card mb-3">
							<div class="card-body">
								<h5 class="card-title">Job Art</h5>
								<app-spinner-with-overlay [showSpinner]="loading">
									<div *ngIf="designHuddleOrder" class="row py-3">
										<div class="col">
											<button [disabled]="reExportLoading" class="btn btn-default btn-sm" (click)="reExportDesignHuddleProject()">
												<i *ngIf="!reExportLoading" class="far fa fa-download"></i><i *ngIf="reExportLoading" class="far fa fa-spinner fa-spin"></i> Re-export DesignHuddle File
											</button>
										</div>
									</div>
									<div *ngIf="!showDesignForDigital" class="row mb-3">
										<div class="col-md-6">
											<figure class="figure">
												<object *ngIf="frontIsPDF && frontPreview" class="figure-img mx-auto d-block mb-3" width="100%"
												type="application/pdf" [data]="frontPreview"></object>
												<img *ngIf="!frontIsPDF && frontPreview"
													class="figure-img img-thumbnail img-fluid mx-auto d-block mb-3"
													[src]="frontPreview" />
												<figcaption class="figure-caption">
													<button type="button" class="btn btn-default btn-xs" *ngIf="frontPreview"
													(click)="downloadFile('front')"><i class="far fa fa-download"></i></button>&nbsp;&nbsp; {{ frontFileName }}&nbsp;&nbsp;
													<button type="button" class="btn btn-default btn-xs me-2" *ngIf="frontPreview"
													(click)="closeModal();openDeleteModal('front');"><i class="far fa fa-trash"></i></button>
												</figcaption>
											</figure>
										</div>
										<div class="col-md-6">
											<figure class="figure" *ngIf="backPreview">
												<object *ngIf="backIsPDF" class="figure-img mx-auto d-block mb-3" width="100%"
												type="application/pdf" [data]="backPreview"></object>
												<img *ngIf="!backIsPDF"
													class="figure-img img-thumbnail img-fluid mx-auto mb-3 d-block mb-3"
													[src]="backPreview" />
												<figcaption class="figure-caption">
													<button type="button" class="btn btn-default btn-xs"
													(click)="downloadFile('back')"><i class="far fa fa-download"></i></button>&nbsp;&nbsp; {{ backFileName }}&nbsp;&nbsp;
													<button type="button" class="btn btn-default btn-xs me-2"
													(click)="closeModal();openDeleteModal('back');"><i class="far fa fa-trash"></i></button>
												</figcaption>
											</figure>
										</div>
									</div>
									<div class="row" *ngIf="!showDesignForDigital && orderLayoutService.isDesignTeam">
										<div class="col-md-6">
											<div>
												<label for="artFront" class="form-label">Front File</label>
												<input #artFrontInput class="form-control" type="file" id="artFront"
												(change)="previewFile($event, true)">
											</div>

											<select class="form-select my-1" aria-label="Front File Orientation"
												[(ngModel)]="frontOrientation">
												<option value="" [disabled]="true">Select Orientation</option>
												<option [ngValue]="'Vertical'">Portrait</option>
												<option [ngValue]="'Horizontal'">Landscape</option>
											</select>
											<div class="my-2">
												<object
													*ngIf="(frontFilePath && frontFilePath !== '') && newFrontIsPDF"
													[data]="frontFilePath" type="application/pdf"
													class="mx-auto d-block" style="max-width:100%"></object>
												<img *ngIf="(frontFilePath && frontFilePath !== '') && !newFrontIsPDF"
													[src]="frontFilePath"
													class="img-thumbnail img-fluid mx-auto d-block">
											</div>
										</div>
										<div class="col-md-6">
											<div>
												<label for="artBack" class="form-label">Back File</label>
												<input #artBackInput class="form-control" type="file" id="artBack"
												(change)="previewFile($event, false)">
											</div>

											<select class="form-select my-1" aria-label="Back File Orientation"
												[(ngModel)]="backOrientation">
												<option value="" [disabled]="true">Select Orientation</option>
												<option [ngValue]="'Vertical'">Portrait</option>
												<option [ngValue]="'Horizontal'">Landscape</option>
											</select>
											<div class="my-2">
												<object
													*ngIf="(backFilePath && backFilePath !== '') && newBackIsPDF"
													class="mx-auto d-block" type="application/pdf"
													[data]="backFilePath" style="max-width:100%"></object>
												<img *ngIf="(backFilePath && backFilePath !== '') && !newBackIsPDF"
													[src]="backFilePath"
													class="img-thumbnail img-fluid mx-auto d-block">
											</div>
										</div>
										<div class="col-12">
											<button [disabled]="allowUpload()" (click)="submit()" class="btn btn-primary"
											type="submit">{{ uploadButtonText }}</button>
										</div>
									</div>
									<div class="row" *ngIf="showDesignForDigital">
										<div class="upload-container mt-2 card-body">
											<div class="ratio" style="--bs-aspect-ratio: 20%;">
												<div appDragAndDrop class="dropzone text-primary d-flex align-items-center" (fileDropped)="onFileDropped($event)">
													<input type="file" multiple #fileInput id="fileDropRef"
														(change)="fileHandler($event.target)" />
													<span class="fa fa-2x fa-upload"></span>&nbsp;Drop file or click here to upload
												</div>
											</div>
											<ul class="list-unstyled">
												<li *ngFor="let file of digitalDesigns" class="mt-3">
													<button type="button" class="btn btn-default btn-xs"
													(click)="download(file)"><i class="far fa fa-download"></i></button> &nbsp;{{file}}&nbsp;
													<button type="button" class="btn btn-default btn-xs"
													(click)="deleteDigitalDesign(file)"><i class="far fa fa-times"></i></button>
												</li>
											</ul>
										</div>
									</div>

								</app-spinner-with-overlay>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<app-purchase-order [printers]="orderLayoutService.printers"></app-purchase-order>
					</div>
				</div>
			</div>
			<div class="tab-pane fade card-body" id="deliveryView"
				*ngIf="orderLayoutService.selectedOrderItemAsCartProduct?.drops || this.isLegacyDigital || (orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId === uSelect.qrCode && !!qrCodeMetaInfo)">
				<app-order-item-delivery [countryCode]="countryCode" [qrCodeMetaInfo]="qrCodeMetaInfo"></app-order-item-delivery>
			</div>
			<div class="tab-pane fade card-body" id="previewFrontView" *ngIf="frontPreview">
				<div class="row mb-2">
					<div class="col" *ngIf="frontFileName">
						<b>{{ frontFileName }}</b>
					</div>
					<div *ngIf="designHuddleOrder && orderLayoutService.isDesignTeam" class="col-8 text-start">
						<button [disabled]="reExportLoading" class="btn btn-default btn-sm" (click)="reExportDesignHuddleProject()">
							<i *ngIf="!reExportLoading" class="far fa fa-download"></i><i *ngIf="reExportLoading" class="far fa fa-spinner fa-spin"></i> Re-export DesignHuddle File
						</button> &nbsp;&nbsp;
						<button class="btn btn-default btn-sm" (click)="openChangeDesignHuddleProjectModal()">
						<span *ngIf="!reExportLoading">
							<i class="fas fa fa-exchange"></i> Change Related DH Project
						</span>
						<span *ngIf="reExportLoading">
							<i class="far fa fa-spinner fa-spin"></i> Project Changed, Re-exporting
						</span></button>&nbsp;&nbsp;
						<a class="btn btn-default btn-sm" (click)="navigateToDownloadAssets()"><i class="far fa fa-download"></i> Download Project Assets</a>
					</div>
					<div class="col ms-auto text-end">
						<button class="btn btn-default btn-sm" (click)="downloadFile('front')"><i class="far fa fa-download"></i> Download Front File</button>
					</div>
				</div>
				<img class="img-fluid" [src]="frontPreview" *ngIf="!frontIsPDF" />
				<object [data]="frontPreview" type="application/pdf" *ngIf="frontIsPDF"
				style="min-width: 100%; min-height: 70vh;"></object>
			</div>
			<div class="tab-pane fade card-body" id="previewBackView" *ngIf="backPreview">
				<div class="row mb-2">
					<div class="col">
						<b>{{ backFileName }}</b>
					</div>
					<div class="col ms-auto text-end">
						<button class="btn btn-default btn-sm" (click)="downloadFile('back')"><i class="far fa fa-download"></i> Download Back File</button>
					</div>
				</div>
				<img class="img-fluid" [src]="backPreview" *ngIf="!backIsPDF" />
				<object *ngIf="backIsPDF" [data]="backPreview" type="application/pdf" style="min-width: 100%; min-height: 70vh;"></object>
			</div>
			<div class="tab-pane fade card-body" id="previewRaw" *ngIf="orderLayoutService.isSystemAdmin">
				<textarea class="form-control"
					rows="40">{{orderLayoutService.selectedOrderItem?.orderItem?.xmlData}}</textarea>
			</div>
			<div class="tab-pane fade card-body" id="previewProduct" *ngIf="orderLayoutService.isSystemAdmin">
				<textarea class="form-control" [ngModel]="previewCartProduct()" rows="40"></textarea>
			</div>
			<div class="tab-pane fade card-body" id="history">
				<div class="table-responsive table-wrapper scrollheader" *ngIf="orderLayoutService.numberOfTimesChanged > 1" >
					<h5>Change Order History</h5>
					<table class="table table-bordered table-hover table-panel">
						<thead>
							<tr>
								<th>Line Type</th>								
								<th *ngFor="let number of [].constructor(orderLayoutService.numberOfTimesChanged); let changeIndex = index;">
									<span *ngIf="changeIndex === 0">
										Original
										<br />
										<small>Quantity / Amount</small>
									</span>
									<span *ngIf="changeIndex !== 0">
										Change #{{changeIndex}}
										<br />
										<small>Quantity / Amount</small>
									</span>
									<span>
										<br/>
										<small>Date: {{getCreatedDate(changeIndex) | date: 'short'}}</small>
										<br />
										<small>Created By: {{getCreatedBy(changeIndex)}}</small>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let lineTypeHistory of orderLayoutService.selectedOrderItemHistory?.orderItemLines" class="settingRow">
								<td>{{ lineTypeHistory.lineType }}</td>
								<td *ngFor="let history of lineTypeHistory.lines">
									{{(history.quantity ?? '0') | number}} / {{(history.amount ?? '0') | currency}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
                <hr *ngIf="orderLayoutService.numberOfTimesChanged > 1" class="solid"/>
				<app-audit [heading]="'Order Item Audit History'" [audit]="orderLayoutService.selectedOrderItemHistory?.auditData"></app-audit>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #designHuddleProjectsModal let-modal>
	<app-spinner-with-overlay [showSpinner]="loadingDesignHuddle">
		<div class="modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header ps-5">
					<h4 class="modal-title">Customer's DH Projects</h4>
					<button type="button" class="btn-close" (click)="closeModals()"></button>
				</div>
				<div class="modal-body">
					<div class="lead">
						<div class="row mt-3 page-row font-primary">
							<div class="col-2 offset-1">
								<button type="button" class="btn btn-primary round" (click)="previousPage()" [disabled]="currentPage < 1">Previous Page</button>
							</div>
							<div class="col-6">Page {{currentPage + 1}} of {{pageCount}}</div>
							<div class="col-2">
								<button type="button" class="btn btn-primary round" (click)="nextPage()" [disabled]="currentPage >= pageCount - 1">Next Page</button>
							</div>
						</div>
						<div class="col-10 project-browser-container offset-1">
							<div class="row">
								<div class="col-4" *ngFor="let project of pageProjects">
									<div class="font-primary" style="background-color:#ffffff;padding:10px; min-height:200px">
										<div class="row">
											<div [ngClass]="projectClass(project)" class="col-sm-12 mt-2">
												<div class="row">
													<div class="col text-center">
														<strong>Size:</strong> {{project.height - .25}} x {{project.width - .25}}<br />
														<strong>Created:</strong> {{project.createdDate | date: 'medium'}} <br />
														<strong>Last Updated:</strong> {{project.lastEditedDate | date: 'medium'}}<br /><br />
													</div>
												</div>
												<div class="text-center img-wrapper">
													<img class="mw-100 mh-100" [src]="project.thumbnail">
												</div>
												<div class="text-center mt-2">
													<div style="overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">
														{{project.title}}
													</div>
												</div>
												<div class="text-center my-2">
													<a class="btn btn-xs round" [ngClass]="selectButtonProjectClass(project)" (click)="selectProject(project)">
														<span class="fas fa-check"></span>
														&nbsp;Select Project
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer text-end">
					<button type="button" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="this.projectId.length === 0"
						(click)="changeDesignHuddleProject()">Change Project</button> &nbsp;
					<button type="button" class="btn btn-danger" (click)="closeModals()">Cancel</button>
				</div>
			</div>
		</div>
	</app-spinner-with-overlay>
</ng-template>

<ng-template #deleteDesignModal let-modal>
	<app-confirm-modal title="Delete Design" confirmText="delete" (confirmAction)="deleteDesignFile()" (closeModal)="closeModal()">
		<p>Are you sure you want to delete the {{this.designFileTypeToDelete}} file - {{this.designFileToDelete}}?</p>
	</app-confirm-modal>
</ng-template>
