import { Environment } from './environmentType';

export const environment: Environment = {
	production: false,
	kind: 'Development',
	instance: 'Canada',
	adminApiUrl: 'https://admin-api.dev-ca.taradel.com',
	webApiUrl: 'https://web-api.dev-ca.taradel.com',
	templateServerUrl: 'https://templateserver.eddmsite.com',
	oidc: {
		authority: 'https://identity.dev-ca.taradel.com',
		client_id: 'taradel-admin-dev-ca',
		redirect_uri: 'https://admin.dev-ca.taradel.com/login-callback',
		silent_redirect_uri: 'https://admin.dev-ca.taradel.com/login-silent',
		automaticSilentRenew: true,
		response_type: 'id_token token',
		scope: 'openid profile taradel-web-api admin.profile',
		post_logout_redirect_uri: 'https://admin.dev-ca.taradel.com/'
	}
};
