import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AddOrderItemLinesQuery, AddOrderItemQuery, Order, OrderItem, OrderItemLine, QuoteLine, RefundOrderItemLinesQuery, SiteBundle } from '@taradel/admin-api-client';
import { OrderService } from 'services/order.service';
import { ChangeOrdersService } from 'services/change-orders.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'services/toast.service';
import { USelect } from 'services/distributions.service';
import { PrintAndShipService } from 'services/print-and-ship.service';
import { BundlesService } from 'services/bundles.service';

@Component({
  selector: 'app-change-order',
  templateUrl: './change-order.component.html',
  styleUrls: ['./change-order.component.scss']
})
export class ChangeOrderComponent implements OnInit {
	loading = false;
	orderId?: number;
	order?: Order;
	originalOrderItems?: OrderItem[];
	modifiedOrderItems: OrderItem[] = [];

	orderItemLinesToRefund: OrderItemLine[] = [];
	orderItemIdsToDelete: number[] = [];
	orderItemsToDelete?: {orderItem: string, orderItemId: number}[];
	orderItemToUpdate?: number;
	orderItemToUpdateHasLines = false;
	pendingOrderItems: AddOrderItemQuery[] = [];
	modalRef: any;
	addNewOrderItem = false;
	addNewPNSOrderItem = false;
	modifyingOrder = false;
	hasPNSProducts = false;
    siteBundles: SiteBundle[] = [];

	orderLoaded$ = new Subscription();

	@ViewChild('deleteOrderItemsModal') deleteOrderItemsModal!: ElementRef;

	constructor(private route: ActivatedRoute,
		private orderService: OrderService,
		private changeOrdersService: ChangeOrdersService,
		public orderLayoutService: OrderlayoutService,
		private toastService: ToastService,
		private printAndShipService: PrintAndShipService,
		private modalService: NgbModal,
		private bundlesService: BundlesService
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(async params => {
			this.orderId = parseInt(params.get('orderId') ?? '0', 10);
			await this.getOrder();
			if (!!this.order) {
				let distributionId = 0;
				this.originalOrderItems?.forEach(item => {
					const parser = new DOMParser();
					const xmlData = parser.parseFromString(item.xmlData!, 'text/xml');
					const parsedDistributionId = parseInt(xmlData.children[0]?.getAttribute('DistributionId') ?? '0');
					if (parsedDistributionId > 0) {
						distributionId = parsedDistributionId;
					}
				});
				this.siteBundles = await this.bundlesService.getDistributionRelatedBundles(this.order.affiliateId, distributionId);
			}
		});
	}

	async getOrder() {
		this.loading = true;
		this.order = await this.orderService.getOrder(this.orderId!);
		this.originalOrderItems = this.order?.items?.filter(item => !item.deleted);
		this.modifiedOrderItems = this.originalOrderItems ?? [];
		const siteCategories = await this.printAndShipService.getSiteCategories(this.order.affiliateId);
		this.hasPNSProducts = !!siteCategories && siteCategories.length > 0;
		this.loading = false;
	}

	handleMarkingItemAsDeleted(value: { orderItemId: number, deleted: boolean}) {
		if (value.deleted) {
			const isDeleted = this.orderItemIdsToDelete.some(id => id === value.orderItemId);
			if (!isDeleted) {
				this.orderItemIdsToDelete.push(value.orderItemId);
			}
		}
		else {
			const index = this.orderItemIdsToDelete.findIndex(id => id === value.orderItemId);
			if (index > -1) {
				this.orderItemIdsToDelete.splice(index, 1);
			}
		}
	}

	removeOrderItemLines(lines: OrderItemLine[]) {
		if (lines.length) {
			lines.forEach(line => {
				const existingLineIndex = this.orderItemLinesToRefund.findIndex(i => i.orderItemLineId === line.orderItemLineId && i.orderItemId === line.orderItemId);
				if (existingLineIndex > -1) {
					this.orderItemLinesToRefund.splice(existingLineIndex, 1);
				}
			});
		}
	}

	addOrderItemLines(lines: OrderItemLine[]) {
		lines.forEach(line => {
			this.orderItemLinesToRefund.push(line);
		});
	}

	async initiateUpdateOrderItem(orderItem: OrderItem) {
		this.orderItemToUpdateHasLines = !!orderItem.lines && orderItem.lines.length > 0;
		this.orderItemToUpdate = orderItem.orderItemId;
		const itemCartProduct = await this.orderService.getOrderItemCartProduct(orderItem.orderItemId);
		if (itemCartProduct.uSelectId === USelect.printAndShip) {
			this.addNewPNSOrderItem = true;
		}
		this.modifyingOrder = true;
	}

	initiateAddNewOrderItem() {
		this.orderItemToUpdate = undefined;
		this.addNewOrderItem = true;
		this.modifyingOrder = true;
	}

	initiateAddNewPNSOrderItem() {
		this.orderItemToUpdate = undefined;
		this.modifyingOrder = true;
		this.addNewPNSOrderItem = true;
	}

	saveOrderItem(query: AddOrderItemQuery) {
		this.pendingOrderItems.push(query);
	}

	async orderUpdated() {
		await this.getOrder();
		this.cancelUpdateOrderItem();
		await this.orderLayoutService.loadNewOrderData();
	}

	cancelUpdateOrderItem() {
		this.orderItemToUpdate = undefined;
		this.modifyingOrder = false;
		this.addNewPNSOrderItem = false;
		this.addNewOrderItem = false;
	}

	async saveChanges() {
		if (this.orderItemLinesToRefund.length) {
			await this.handleRefundingLines();
		}
		if (this.orderItemIdsToDelete.length) {
			this.openDeleteModal();
		}
	}

	async handleRefundingLines() {
		this.loading = true;
		let queries: AddOrderItemLinesQuery[] = [];

		let orderItemIds: number[] = [];
		this.orderItemLinesToRefund.forEach(line => {
			orderItemIds.push(line.orderItemId);
		});
		orderItemIds = [...new Set(orderItemIds.flat(1))];
		this.orderItemLinesToRefund.map(line => {
			const quoteLine = {
				quantity: line.quantity,
				unitPrice: line.unitPrice,
				amount: line.amount,
				lineType: line.lineType,
				createdDate: line.createdDate,
				createdById: line.createdById,
				description: line.description
			} as QuoteLine;

			if (queries.some(query => query.orderItemId === line.orderItemId)) {
				queries.find(query => query.orderItemId === line.orderItemId)?.lines?.push(quoteLine);
			}
			else {
				let lines: QuoteLine[] = [];
				lines.push(quoteLine);
				const query = {
					orderItemId: line.orderItemId,
					lines: lines
				} as AddOrderItemLinesQuery;
				queries.push(query);
			}
		});
		const refundLinesQuery = {
			orderId: this.orderId,
			addNewLinesQueries: queries
		} as RefundOrderItemLinesQuery;
		let success = true;
		try {
			await this.changeOrdersService.refundOrderItemLines(refundLinesQuery);
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Lines could not be refunded');
			success = false;
		}
		finally {
			this.loading = false;
		}

		if (success) {
			this.orderItemLinesToRefund = [];
			await this.getOrder();
			await this.orderLayoutService.loadNewOrderData();
		}
	}

	openDeleteModal() {
		this.modalRef = this.modalService.open(this.deleteOrderItemsModal);
	}

	async confirmDeleteOrderItem() {
		this.modalService.dismissAll();
		await this.deleteOrderItems();
	}

	closeModal() {
		this.modalService.dismissAll();
	}

	async deleteOrderItems() {
		this.loading = true;
		let success = true;
		try {
			for (let i = 0; i < this.orderItemIdsToDelete.length; i++) {
				await this.changeOrdersService.deleteOrderItem(this.orderItemIdsToDelete[i]);
			}
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Order items could not be deleted');
			success = false;
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.orderItemIdsToDelete = [];
			await this.getOrder();
			await this.orderLayoutService.loadNewOrderData();
		}
	}

	getProductName(orderItemId: number): string {
		const baseProductId = this.originalOrderItems?.find(item => item.orderItemId === orderItemId)?.productId;
		return this.orderLayoutService.selectedOrderItems?.find(i => i.orderItem.productId === baseProductId)?.productName ?? '';
	}

    isRequiredPartOfBundle(orderItemId: number) {
        const orderBundle = this.order?.orderBundles?.find(x => x.items?.some(bi => bi.orderItemId === orderItemId));
        const siteBundle = this.siteBundles.find(x => x.bundleId === orderBundle?.bundleId);
        const orderItem = this.order?.items?.find(oi => oi.orderItemId === orderItemId);
        return siteBundle?.bundle?.bundleItems?.some(bi => bi.productConfiguration?.includes(orderItem?.productId!) && bi.required);
    }
    getBundleName(orderItemId: number) {
        const orderBundle = this.order?.orderBundles?.find(x => x.items?.some(bi => bi.orderItemId === orderItemId));
        const siteBundle = this.siteBundles.find(x => x.bundleId === orderBundle?.bundleId);
        return siteBundle?.bundle?.name;
    }
}
