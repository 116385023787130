import Currency from 'currency.js';

export function CurrencySum(values: number[], substituteZeroForNullish: boolean = false): number {
	return CurrencySumAsCurrency(values, substituteZeroForNullish).value;
}

export function CurrencySumAsCurrency(values: number[], substituteZeroForNullish: boolean = false): Currency {
	let total = Currency(0);
	values.forEach((value) => {
		if (substituteZeroForNullish) {
			value = value ?? 0;
		}
		total.add(Currency(value));
	});
	return total;
}

